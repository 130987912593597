import bootstrap from '../scss/bootstrap-custom.scss';
import template from './AlertMessage.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { nullThrow } from "./TypeScriptFunctions"

export class AlertMessage extends HTMLElement {
    private _alertSpan: HTMLElement;
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], ['container']);
        this._alertSpan = nullThrow(this.shadowRoot?.getElementById('alert-span'));
    }

    connectedCallback() {
        // By default, AlertMessage is an error.
        this.toggleAttribute('data-message-is-error', true);
    }

    showSuccessMessage(message: string, isInnerHtmlMessage: boolean = false) {
        if (isInnerHtmlMessage) {
            this.innerHTML = message;
        } else {
            this.innerText = message;
        }
        this._alertSpan.classList.toggle('text-danger', false);
        this._alertSpan.classList.toggle('text-success', true);
        this.toggleAttribute('data-message-is-error', false);
        this.toggleAttribute('data-message-is-success', true);
        this.toggleAttribute("hidden", false);
    }

    showErrorMessage(message: string, isInnerHtmlMessage: boolean = false) {
        if (isInnerHtmlMessage) {
            this.innerHTML = message;
        } else {
            this.innerText = message;
        }
        this._alertSpan.classList.toggle('text-success', false);
        this._alertSpan.classList.toggle('text-danger', true);
        this.toggleAttribute('data-message-is-success', false);
        this.toggleAttribute('data-message-is-error', true);
        this.toggleAttribute("hidden", false);
    }

    clearMessage() {
        this.innerHTML = '';
        this.toggleAttribute("hidden", true);
    }
}

customElements.define('alert-message', AlertMessage);